import * as React from "react";
import { FunctionComponent } from "react";
import SubmissionSuccessPage from "../templates/SubmissionSuccess/SubmissionSuccess";

const EstatementCustomerServiceSuccess: FunctionComponent = (): JSX.Element => (
  <SubmissionSuccessPage
    heading="eStatement Customer Service Submitted"
    content="Thank you. Your message has been received. A representative with Hawaiiana Management Company will be in touch with you shortly!"
  />
);

export default EstatementCustomerServiceSuccess;
